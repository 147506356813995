<!--
 * @Description: 课程资源管理
 * @Author: xiawenlong
 * @Date: 2021-04-22 11:43:21
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-08-05 10:56:10
-->
<template>
  <div class="course-resources">
    <table-list
      title="资源列表"
      :loading="loading"
      :data="studentList"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: true, fixScrollTable: true }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <el-dialog
      :title="videoTitle"
      :close-on-click-modal="false"
      class="play-dialog"
      :visible.sync="playDialogVisible"
      :before-close="close"
    >
      <div id="prism-player" class="prism-player"></div>
    </el-dialog>
    <Grouping ref="Grouping" />
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '视频资源分组',
    method: _this.handleGrouping,
  },
  {
    label: '添加课程资源',
    method: _this.handleAdd,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'danger',
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '视频资源名称',
    prop: 'keyword',
  },
  {
    label: '时长',
    type: 'select',
    prop: 'time',
    children: [
      { label: '小于30分钟', value: 1 },
      { label: '30分钟~60分钟', value: 2 },
      { label: '60分钟~120分钟', value: 3 },
      { label: '120分钟以上', value: 4 },
    ],
  },
  {
    label: '分组',
    type: 'select',
    prop: 'groupId',
    children: _this.groupList.map(v => ({ label: v.groupName, value: v.groupId })),
  },
  // {
  //   label: '资源类别',
  //   type: 'select',
  //   prop: 'materialType',
  //   children: [
  //     { label: '视频', value: 1 },
  //     { label: '音频', value: 2 },
  //   ],
  // },
]
// 表格列配置
const columns = _this => [
  {
    prop: 'id',
    label: 'ID',
    minWidth: 50,
  },
  {
    prop: 'coverImageUrl',
    label: '资源封面',
    render: (h, { row }) => {
      return [
        row.coverImageUrl
          ? h('img', {
              domProps: { src: row.coverImageUrl },
              style: 'height: 50px;cursor:pointer',
              on: {
                click: () => {
                  _this.dialog(row)
                },
              },
            })
          : h('img', {
              domProps: { src: '../../../assets/course/emptyVideo.jpg' },
              style: 'height: 50px;cursor:pointer',
              on: {
                click: () => {
                  _this.dialog(row)
                },
              },
            }),
      ]
    },
    minWidth: 150,
  },
  // {
  //   prop: 'materialType',
  //   label: '资源类别',
  //   formatter: row => {
  //     return ['视频', '音频'][row.materialType - 1]
  //   },
  // },
  {
    prop: 'name',
    label: '资源名称',
    minWidth: 150,
  },
  {
    prop: 'durationStr',
    label: '资源时长',
    sortable: 'sortable',
    align: 'right',
    minWidth: 120,
  },
  {
    prop: 'groupName',
    label: '分组',
    minWidth: 150,
  },
  {
    prop: 'uploadTime',
    label: '上传时间',
    minWidth: 150,
  },
]
// 表格操作按钮配置
const operates = _this => [
  // {
  //   label: '试看',
  //   method: _this.handlePlay,
  // },
  {
    label: '编辑',
    method: _this.handleEdit,
  },
]
import TableList from '@/components/TableList'
import Grouping from './components/grouping.vue'
import { groupList, courseResourses, resoursesDel, videoPlayInfo } from '@/api/course'
import to from 'await-to'
var player
export default {
  name: 'CourseResources',
  components: {
    TableList,
    Grouping,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      operates,
      loading: false,
      studentList: [],
      selectStudents: [],
      deptList: [],
      classList: [],
      groupList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      videoTitle: '',
      playDialogVisible: false,
    }
  },
  mounted() {
    this.getCourseResource()
    this.groupListData()
  },
  methods: {
    async getCourseResource() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(courseResourses({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.studentList = res.data.list
      this.pager.total = res.data.total
    },
    async groupListData() {
      const [res, err] = await to(groupList({ groupName: '' }))
      if (err) return this.$message.warning(err.msg)
      this.groupList = res.data
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getCourseResource()
    },
    // 新增
    handleAdd() {
      this.$router.push('/course/resources/add')
    },
    // 编辑
    handleEdit({ id }) {
      this.$router.push(`/course/resources/edit/${id}`)
    },
    handleGrouping() {
      // this.$refs.Grouping.show()
      this.$router.push('group')
    },
    async handlePlay(item) {
      // this.videoTitle = item.name
      // this.playDialogVisible = true
      // this.$nextTick(() => {
      //   // eslint-disable-next-line no-undef
      //   player = new Aliplayer({
      //     id: 'prism-player',
      //     cover: item.coverImageUrl,
      //     source: url,
      //     width: '100%',
      //     autoplay: true,
      //     isLive: false,
      //     rePlay: true,
      //     controlBarVisibility: 'hover',
      //   })
      // })
      const url = await this.getPlayUrl(item.videoPlatform, item.videoId)
      if (!url) return
      if (player) {
        player.loadByUrl(url)
        return
      }
    },
    dialog(item) {
      this.videoTitle = item.name
      this.playDialogVisible = true
      this.$nextTick(() => {
        // eslint-disable-next-line no-undef
        player = new Aliplayer({
          id: 'prism-player',
          width: '100%',
          autoplay: true,
          isLive: false,
          rePlay: true,
          controlBarVisibility: 'hover',
        })
      })
      this.handlePlay(item)
    },
    close() {
      player.dispose()
      this.playDialogVisible = false
    },
    async getPlayUrl(videoPlatform, videoId) {
      const [res, err] = await to(videoPlayInfo({ videoPlatform, videoId }))
      if (err) {
        this.$message.warning(err.msg)
        return false
      }
      return res.data.videoUrl
    },
    // 删除
    async handleDelete() {
      if (!this.selectStudents.length) return this.$message.warning('请先选择要删除的课程资源')
      let resources = []
      this.selectStudents.map(item => {
        resources.push({ id: item.id, materialType: item.materialType })
      })
      this.$confirm('确认删除资源吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(resoursesDel({ resources }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getCourseResource()
      })
    },
    // 多选
    handleSelectionChange(val) {
      this.selectStudents = val
      console.log(val)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getCourseResource()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getCourseResource()
    },
  },
}
</script>
<style lang="scss" scoped>
.course-resources {
  ::v-deep.play-dialog {
    .el-dialog {
      width: 870px;
      background-color: #232323;
      .el-dialog__header {
        .el-dialog__title {
          font-size: 20px;
          color: #fff;
        }
        .el-dialog__close {
          font-size: 30px;
        }
      }
      .el-dialog__body {
        padding: 0;
        padding-top: 10px;
      }
      .prism-player {
        width: 100% !important;
        height: 490px !important;
      }
    }
  }
}
</style>
