<template>
  <div class="grouping">
    <el-dialog
      :close-on-click-modal="false"
      class="add-student"
      :show-close="false"
      :visible.sync="dialogVisible"
      width="750px"
    >
      <div class="header">
        <div class="title">视频资源分组管理</div>
        <div class="icon" @click="dialogVisible = false"><i class="el-icon-close"></i></div>
      </div>
      <div class="content">
        <div class="titleSearch">
          <el-form ref="formInline" :inline="true" :model="formInline">
            <el-form-item prop="title">
              <el-input
                v-model="formInline.title"
                placeholder="请输入分组标题"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" class="search" @click="search">查找</el-button>
        </div>
        <el-table
          :data="list"
          class="table"
          :header-cell-style="tableHeaderCellStyle"
          @cell-mouse-enter="mouseEnterTable"
          @cell-mouse-leave="mouseLeaveTable"
        >
          <el-table-column>
            <template slot-scope="scope">
              <el-input v-if="scope.row.addStatus" v-model="scope.row.groupName"></el-input>
            </template>
          </el-table-column>
          <el-table-column property="groupName" label="分组名称">
            <template slot-scope="scope">
              <el-input v-if="scope.row.editStatus" v-model="scope.row.groupName"></el-input>
              <div v-else>{{ scope.row.groupName }}</div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" width="100px">
            <template slot="header">
              <span class="icon" @click="addGroupItem"><i class="el-icon-plus"/></span>
            </template>
            <template slot-scope="scope">
              <div v-if="scope.row.flag" class="operation">
                <span class="edit" @click="edit(scope.row)">
                  <img src="../../../../assets/course/edit.png" alt="" />
                </span>
                <span class="del" @click="deleteItem(scope.row)">
                  <i class="el-icon-circle-close" />
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button :loading="submitLoading" type="primary" @click="submit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { groupList, delGroupList, addGroup } from '@/api/course'
import to from 'await-to'
export default {
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      loading: false,
      formInline: {},
      list: [],
    }
  },
  created() {},
  methods: {
    show() {
      this.dialogVisible = true
      this.groupListData()
    },
    mouseEnterTable(row) {
      row.flag = true
    },
    mouseLeaveTable(row) {
      row.flag = false
    },
    tableHeaderCellStyle() {
      return 'background:#eaeaea !important'
    },
    async groupListData() {
      this.loading = true
      const [res, err] = await to(groupList({ groupName: this.formInline.title }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.list = res.data
      this.list.map(v => {
        this.$set(v, 'flag', false)
        this.$set(v, 'editStatus', false)
        this.$set(v, 'addStatus', false)
      })
    },
    search() {
      this.groupListData()
    },
    async addGroupItem() {
      const [, err] = await to(addGroup({ groupName: this.formInline.title }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('添加成功')
    },
    edit(row) {
      row.editStatus = true
    },
    async deleteItem(row) {
      const [, err] = await to(delGroupList({ groupId: row.groupId }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('删除成功')
    },
    submit() {},
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.groupListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.groupListData()
    },
  },
}
</script>

<style scoped lang="scss">
.grouping {
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  .header {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 31px;
    padding-right: 38px;
    border-bottom: 1px solid #c7c7c7;
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #4d4e5d;
    }
    .icon {
      width: 14px;
      height: 14px;
      cursor: pointer;
      color: #8c8c8c;
    }
  }
  .content {
    padding-left: 31px;
    padding-right: 38px;
    .titleSearch {
      margin-top: 20px;
      display: flex;
      ::v-deep .el-input {
        width: 194px;
      }
      ::v-deep .el-form-item {
        margin-bottom: 17px;
      }
      .search {
        margin-left: 10px;
      }
    }
    ::v-deep .el-table thead tr :nth-child(1) .cell,
    ::v-deep .el-table tbody tr :nth-child(1) .cell {
      padding-left: 52px;
    }
    ::v-deep .el-table tbody tr {
      background-color: #fff;
    }
    ::v-deep .el-table__body tr {
      &:hover {
        .none {
          visibility: visible !important;
        }
      }
    }
    ::v-deep .el-table::before,
    ::v-deep .el-table__fixed-right::before {
      height: 0 !important;
    }
    ::v-deep .el-table tbody td {
      border: none;
    }
    .table {
      .icon {
        cursor: pointer;
        font-size: 20px;
        color: #ff7b33;
        padding-left: 30px;
        i {
          font-weight: bold;
        }
      }
      .operation {
        display: flex;
        .edit {
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            width: 14px;
            height: 14px;
          }
        }
        .del {
          cursor: pointer;
          font-size: 18px;
          color: #ff5642;
          margin-left: 15px;
        }
      }
    }
    .page {
      margin: 20px 0;
    }
  }
  .footer {
    text-align: right;
    padding: 20px 52px 19px 0;
    border-top: 1px solid #c7c7c7;
    ::v-deep .el-button {
      width: 98px;
    }
  }
}
</style>
